<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-form-group>
                <b-form-radio-group id="radio-group-3" v-model="type" name="radio-sub-component">
                  <b-form-radio name="some-radios" value="Bulk Land">Bulk Land</b-form-radio>
                  <b-form-radio name="some-radios1" value="Retail">Retail</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <div v-if="type == 'Bulk Land'">
                <good-table-column-search :key="'bulk-land-table'" :moduleapi="`${this.baseApi}/receipt`"
                  :defaultParams="{ receipt_type: 'Bulk Land' }" :newPage="true" :columns="BulkLandColumns"
                  title="Receipt" routerpath="/accounting/addreceipt" :formOptions="formOptions" :edit="edit" :add="add"
                  :canDelete="canDelete" viewComponent="receiptShow">
                  <template #action="{ row, getGridData }">
                    <div class="d-flex">
                      <b-badge v-if="
                        approvalAccess &&
                        (row.approved1 == 'Pending' || row.approved1 == 'Rejected')
                      " variant="primary" :id="'recieptApprove' + row.id" class="mr-50 cursor-pointer"
                        @click="approveReceipt(row, 'Approved', getGridData)">
                        <b-spinner v-if="row.loading" small variant="light" />
                        <feather-icon v-else icon="CheckIcon" />
                      </b-badge>
                      <b-tooltip v-if="
                        approvalAccess &&
                        (row.approved1 == 'Pending' || row.approved1 == 'Rejected')
                      " :target="'recieptApprove' + row.id" triggers="hover">
                        Approve
                      </b-tooltip>

                      <b-badge v-if="approvalAccess && row.approved1 != 'Rejected'" variant="danger"
                        :id="'recieptReject' + row.id" class="mr-50 cursor-pointer"
                        @click="approveReceipt(row, 'Rejected', getGridData)">
                        <b-spinner v-if="row.loading" small variant="light" />
                        <feather-icon v-else icon="XIcon" />
                      </b-badge>

                      <b-tooltip v-if="approvalAccess && row.approved1 != 'Rejected'" :target="'recieptReject' + row.id"
                        triggers="hover">
                        Reject
                      </b-tooltip>

                      <b-badge :id="'download' + row.id" v-if="
                        row.approved1 == 'Approved' && row.transactiontype !== 'Other'
                      " @click="handelRecieptPDF(row)" variant="success" class="mr-50 cursor-pointer">
                        <b-spinner v-if="row.downloading" small variant="light" />

                        <feather-icon v-else icon="DownloadIcon" />
                      </b-badge>

                      <b-tooltip v-if="
                        row.approved1 == 'Approved' && row.transactiontype !== 'Other'
                      " :id="'download' + row.id" :target="'download' + row.id" triggers="hover">
                        Download
                      </b-tooltip>
                    </div>
                  </template>
                </good-table-column-search>
              </div>
              <div v-else>
                <good-table-column-search :key="'retail-table'" :moduleapi="`${this.baseApi}/receipt`" :newPage="true"
                  :columns="columns" title="Receipt" routerpath="/accounting/addreceipt" :formOptions="formOptions"
                  :edit="edit" :add="add" :canDelete="canDelete" viewComponent="receiptShow">
                  <template #customField="{ props }">
                    <span v-if="props.column.field === 'plots'">
                      {{ setPlots(props.row) }}
                    </span>
                  </template>
                  <template #action="{ row, getGridData }">
                    <div class="d-flex">
                      <b-badge v-if="
                        approvalAccess &&
                        (row.approved1 == 'Pending' || row.approved1 == 'Rejected')
                      " variant="primary" :id="'recieptApprove' + row.id" class="mr-50 cursor-pointer"
                        @click="approveReceipt(row, 'Approved', getGridData)">
                        <b-spinner v-if="row.loading" small variant="light" />
                        <feather-icon v-else icon="CheckIcon" />
                      </b-badge>
                      <b-tooltip v-if="
                        approvalAccess &&
                        (row.approved1 == 'Pending' || row.approved1 == 'Rejected')
                      " :target="'recieptApprove' + row.id" triggers="hover">
                        Approve
                      </b-tooltip>

                      <b-badge v-if="approvalAccess && row.approved1 != 'Rejected'" variant="danger"
                        :id="'recieptReject' + row.id" class="mr-50 cursor-pointer"
                        @click="approveReceipt(row, 'Rejected', getGridData)">
                        <b-spinner v-if="row.loading" small variant="light" />
                        <feather-icon v-else icon="XIcon" />
                      </b-badge>

                      <b-tooltip v-if="approvalAccess && row.approved1 != 'Rejected'" :target="'recieptReject' + row.id"
                        triggers="hover">
                        Reject
                      </b-tooltip>

                      <b-badge :id="'download' + row.id" v-if="
                        row.approved1 == 'Approved' && row.transactiontype !== 'Other'
                      " @click="handelRecieptPDF(row)" variant="success" class="mr-50 cursor-pointer">
                        <b-spinner v-if="row.downloading" small variant="light" />

                        <feather-icon v-else icon="DownloadIcon" />
                      </b-badge>

                      <b-tooltip v-if="
                        row.approved1 == 'Approved' && row.transactiontype !== 'Other'
                      " :id="'download' + row.id" :target="'download' + row.id" triggers="hover">
                        Download
                      </b-tooltip>
                    </div>
                  </template>
                </good-table-column-search>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
      :paginate-elements-by-height="1400" filename="Receipt" :html2canvas="{}" :jsPDF="{
        format: 'a5',
      }" :pdf-quality="3" :manual-pagination="false" pdf-format="a5" :pdf-margin="0" pdf-orientation="landscape"
      pdf-content-width="1800px" ref="html2Pdf">
      <section slot="pdf-content">
        <print-comp :receiptData="receiptData" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import axios from "@/components/axios";
import printComp from "@/views/print/printReceipt.vue";
import VueHtml2pdf from "vue-html2pdf";
import { BFormRadio, BFormRadioGroup, BFormGroup, BTooltip, BBadge, BSpinner } from "bootstrap-vue";

import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    GoodTableColumnSearch,
    BFormRadio, BFormRadioGroup, BFormGroup, BBadge, BTooltip, BSpinner,
    printComp,
    VueHtml2pdf,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      type: 'Retail',
      columns: [
        {
          label: "Voucher Type",
          field: "vouchertype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Voucher Type",
          },
        },
        {
          label: "Receipt Date",
          field: "receiptdate",
          type: 'datetime',
          filterOptions: {
            enabled: true,
            placeholder: "Search Receipt Date",
          },
        },
        {
          label: "Transaction Type",
          field: "transactiontype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Transaction Type",
          },
        },
        {
          label: "Company Name",
          field: "companyname",
          type: "dropdown",
          url: "getCompany",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Company Name",
          },
        },
        {
          label: "Ledger Name",
          field: "ledgername",
          type: "dropdown",
          url: "ledger?pagenum=1&pagesize=100",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Ledger Name",
          },
        },
        {
          label: "Client Name",
          field: "clientname",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Client Name",
          },
        },
        {
          label: "Project Name",
          field: "project",
          type: "dropdown",
          url: "getProject",
          responseValue: "projectname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Project Name",
          },
        },
        {
          label: "Plot No",
          field: "plots",
        },
        {
          label: "Bank Name",
          field: "bankname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bank Name",
          },
        },
        {
          label: "Cheque No",
          field: "chequeno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Chquque No",
          },
        },
        {
          label: "Cheque Date",
          field: "chequedate",
          type: 'datetime',
          filterOptions: {
            enabled: true,
            placeholder: "Search Cheque Date",
          },
        },
        {
          label: "Status",
          field: "approved1",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search Remarks",
          },
        },
        {
          label: "Amount",
          field: "amount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Amount",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      BulkLandColumns: [
        {
          label: "Voucher Type",
          field: "vouchertype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Voucher Type",
          },
        },
        {
          label: "Receipt Date",
          field: "receiptdate",
          type: 'datetime',

          filterOptions: {
            enabled: true,
            placeholder: "Search Receipt Date",
          },
        },
        {
          label: "Transaction Type",
          field: "transactiontype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Transaction Type",
          },
        },
        {
          label: "Company Name",
          field: "companyname",
          type: "dropdown",
          url: "company",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Company Name",
          },
        },
        {
          label: "Ledger Name",
          field: "ledgername",
          type: "dropdown",
          url: "ledger?pagenum=1&pagesize=100",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Ledger Name",
          },
        },
        {
          label: "Client Name",
          field: "clientname",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Client Name",
          },
        },
        {
          label: "Village Name",
          field: "land",
          type: "dropdown",
          url: "allLand",
          slotPrams: 'surveynonew',
          responseValue: "villagename",
          filterOptions: {
            enabled: true,
            placeholder: "Search Project Name",
          },
        },
        {
          label: "Bank Name",
          field: "bankname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bank Name",
          },
        },
        {
          label: "Cheque No",
          field: "chequeno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Chquque No",
          },
        },
        {
          label: "Cheque Date",
          field: "chequedate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Cheque Date",
          },
        },
        {
          label: "Status",
          field: "approved1",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search Remarks",
          },
        },
        {
          label: "Amount",
          field: "amount",
          filterOptions: {
            enabled: true,
            placeholder: "Search Amount",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Receipt" : "Add Receipt"}`,
        submitRouterPath: "/crm/receipt",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/receipt`,
        inputFields: [],
      },
      edit: "",
      add: "",
      canDelete: "",
      receiptData: "",
      approvalAccess: false,
      accessToken: localStorage.getItem("accessToken"), // JSON.parse()
    };
  },
  computed: {
    setPlots() {
      return (row) => {
        let str = "";
        if (row.receipt_items.length > 0) {
          let arr = row.receipt_items.map((item) =>
            item.plot_data ? item.plot_data.plotno : ""
          );
          arr = arr.filter((item) => item);
          str = arr.join(", ");
        }
        return str;
      };
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Receipt") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  watch: {
    "$store.state.app.user_approval": {
      handler: "getAccess",
      immediate: true,
    },
  },
  methods: {
    getAccess() {
      this.approvalAccess = this.$store.getters["app/approvalAccess"]("Receipt");
    },
    async handelRecieptPDF(row) {
      if (row.downloading) {
        return;
      }
      row.downloading = true;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getreceiptById/${row.id}`,
      };

      await axios(requestOptions)
        .then((json) => {
          this.receiptData = json.data.data;
          let year = moment(this.receiptData.enterdatetime).year();
          let id = this.receiptData.id.toString().padStart(5, "0");
          this.receiptData.receiptNo = "NGR-" + year + "-" + id;

          if (this.receiptData && this.receiptData.receipt_items.length > 30) {
            this.$swal({
              title: "Denied",
              text: "Cannot generate receipt which contains more than 30 plot payments",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            console.log('data');

            this.receiptData.paymentDetails = "";
            if (
              parseFloat(this.receiptData?.receipt_items[0]?.remaining) +
              parseFloat(this.receiptData?.receipt_items[0]?.amount) ==
              parseFloat(this.receiptData.receipt_items[0].sales_data.totalpayment)
            ) {
              this.receiptData.paymenttype = this.receiptData?.receipt_items[0].sales_data.paymenttype;
            } else if (
              this.receiptData?.receipt_items[0].sales_data.bookingtype == "EMI"
            ) {
              this.receiptData.paymenttype = "EMI";
            } else {
              this.receiptData.paymenttype = "Part Payment";
            }
            this.$refs.html2Pdf.generatePdf();
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          row.downloading = false;
          this.$forceUpdate();
        });
    },
    async approveReceipt(row, status, getGridData) {
      !row.loading &&
        this.$swal({
          title: `Are you sure to ${status == "Approved" ? "Approve" : "Reject"}?`,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${status == "Approved" ? "Approve" : "Reject"}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then(async (result) => {
            const data = {
              approved1: status,
            };
            row.loading = true;

            if (status == "Approved") {
              data.approved1datetime = moment(new Date()).format();
            }
            if (result.value) {
              await axios({
                method: "put",
                url: `${this.baseApi}/receipt/${row.id}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
                data: JSON.stringify(data),
              })
                .then((json) => {
                  if (json.data.status == 200 || json.data) {
                    this.$swal({
                      icon: "success",
                      title: `${status == "Approved" ? "Approved" : "Rejected"}`,
                      text: `Your data has been ${status == "Approved" ? "Approved" : "Rejected"
                        }.`,
                      customClass: {
                        confirmButton: "btn btn-success",
                      },
                    });
                    getGridData();
                  }
                })
                .catch((error) =>
                  this.$swal({
                    title: "Error!",
                    text: `${error.response?.data?.message}`,
                    icon: "error",
                    timer: 5000,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  })
                )
                .finally(() => {
                  row.loading = false;
                  this.$forceUpdate();
                });
            }
          })
          .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
